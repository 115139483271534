import React, { useState, useEffect } from 'react';
import { ConfigProvider, ErrorBlock } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useParams } from 'react-router-dom';

import zhHK from 'antd-mobile/es/locales/zh-HK';
import enUS from 'antd-mobile/es/locales/en-US';

import * as ToolRequest from '../../tools/ToolRequest';

import ErrorScreen from '../Error/ErrorScreen';
import LoginScreen from '../Login/LoginScreen';
import HomeScreen from '../Home/HomeScreen';
import ChargerScreen from '../Charger/ChargerScreen';

import SpinFull from '../../components/SpinFull';

import { updateEvCharger } from '../../reducers/evChargerSlice';
import { updateOrganization } from '../../reducers/organizationSlice';

export default function SplashScreen(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const userData = useSelector(state => state.user.value);

  const chargerSlotId = params.chargerSlotId;

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const evChargerSlot = await ToolRequest.request('GET', `/v1/ev-charger-slot/${chargerSlotId}`);
        const evCharger = await ToolRequest.request('GET', `/v1/ev-charger/${evChargerSlot.chargerId}`);
        dispatch(updateEvCharger({
          ...evCharger,
          evChargerSlot,
        }));

        const organization = await ToolRequest.request('GET', `/v1/organization/${evCharger.organizationId}`);
        dispatch(updateOrganization(organization));

        setLoaded(true);
      } catch (err) {
        setError(true);
      }
    })();
  }, []);

  let locale = enUS;
  if (userData) {
    switch (userData.locale) {
      case 'zh-tw':
        locale = zhHK;
        break;
    }
  }

  return (
    <ConfigProvider locale={locale}>
      {error ? (
        <ErrorScreen />
      ) : (
        loaded ? (
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/charger" element={<ChargerScreen />} />
            <Route index element={<HomeScreen />} />
          </Routes>
        ) : (
          <SpinFull />
        )
      )}
    </ConfigProvider>
  );
};
