import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavBar, Card, Space, Button, Input, Image, Tag, Toast } from 'antd-mobile';
import { GlobalOutline } from 'antd-mobile-icons';

import * as ToolRequest from '../../tools/ToolRequest';

import { updateUser } from '../../reducers/userSlice';

import logo from '../../images/logo-horizontal-white.png';
import HeaderRight from '../../components/HeaderRight';

export default function LoginScreen(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const evChargerData = useSelector(state => state.evCharger.value);
  const organizationData = useSelector(state => state.organization.value);

  const [loading, setLoading] = useState(false);
  const [showingCode, setShowingCode] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const submit = async () => {
    if ((showingCode && !code) || (!showingCode && !email)) {
      return;
    } else if (showingCode && code && code.length < 16) {
      Toast.show({
        icon: 'fail',
        content: I18n.t('loginScreenErrorInvalidCodeLength'),
      });
      return;
    }

    setLoading(true);

    try {
      const data = {
        chargerSlotId: evChargerData.evChargerSlot.id,
      };

      if (showingCode) {
        data.code = code;
      } else {
        data.email = email;
      }

      const res = await ToolRequest.request('POST', `/v1/auth/visitor/verify`, data);
      dispatch(updateUser(res));

      navigate(`/${evChargerData.evChargerSlot.id}/charger`);

      Toast.show({
        icon: 'success',
        content: I18n.t('loginScreenSuccessMessage'),
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        switch (err.response.data) {
          case 'INVALID_EMAIL': {
            Toast.show({
              icon: 'fail',
              content: I18n.t('loginScreenErrorInvalidEmail'),
            });
            break;
          }
          case 'CHARGER_SLOT_NOT_FOUND': {
            Toast.show({
              icon: 'fail',
              content: I18n.t('loginScreenErrorInvalidChargerSlot'),
            });
            break;
          }
          case 'VISITOR_NOT_FOUND': {
            Toast.show({
              icon: 'fail',
              content: I18n.t('loginScreenErrorVisitorNotFound'),
              duration: 5000,
            });
            break;
          }
          case 'USER_EXISTS': {
            Toast.show({
              icon: 'fail',
              content: I18n.t('loginScreenErrorUserExists'),
              duration: 5000,
            });
            break;
          }
          default: {
            Toast.show({
              icon: 'fail',
              content: I18n.t('errorUnknown'),
            });
            break;
          }
        }
      }

      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <NavBar
        onBack={() => navigate(-1)}
        right={<HeaderRight />}
      />

      <div className="header">
        <Image src={logo} fit="scale-down" />
      </div>

      <Card
        title={<>
          <div className="organization-name">{organizationData.displayName}</div>
          <div className="charger-name">
            <Tag color="#DFEBF0">{evChargerData.name}</Tag>
          </div>
        </>}
      >
        <form onSubmit={e => e.preventDefault()}>
          {showingCode ? (
            <Space
              direction="vertical"
              style={{ '--gap': '24px' }}
              block
            >
              <div>{I18n.t('loginScreenLabelCode')}</div>

              <Input
                placeholder="xxxxxxxxxxxxxxxx"
                style={{ '--font-size': '14px', '--text-align': 'center' }}
                maxlength={16}
                value={code}
                onChange={value => setCode(value.toUpperCase().substr(0, 16))}
              />

              <Button
                block
                type="submit"
                color="primary"
                shape="rounded"
                size="large"
                loading={loading}
                onClick={() => submit()}
              >
                {I18n.t('loginScreenButtonSubmit')}
              </Button>

              <Button
                block
                fill="none"
                size="mini"
                onClick={() => setShowingCode(false)}
                style={{ '--text-color': '#5D9CB4' }}
              >
                {I18n.t('loginScreenButtonEmail')}
              </Button>
            </Space>
          ) : (
            <Space
              direction="vertical"
              style={{ '--gap': '24px' }}
              block
            >
              <div>{I18n.t('loginScreenLabelEmail')}</div>

              <Input
                placeholder="your@email.com"
                type="email"
                name="email"
                style={{ '--font-size': '18px', '--text-align': 'center' }}
                value={email}
                onChange={value => setEmail(value)}
              />

              <Button
                block
                type="submit"
                color="primary"
                shape="rounded"
                size="large"
                loading={loading}
                onClick={() => submit()}
              >
                {I18n.t('loginScreenButtonSubmit')}
              </Button>

              <Button
                block
                fill="none"
                size="mini"
                onClick={() => setShowingCode(true)}
                style={{ '--text-color': '#5D9CB4' }}
              >
                {I18n.t('loginScreenButtonCode')}
              </Button>
            </Space>
          )}
        </form>
      </Card>
    </div>
  );
};
