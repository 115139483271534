import React, { useState, useEffect } from 'react';
import { I18n, setLocale } from 'react-redux-i18n';
import { Button, Modal } from 'antd-mobile';
import { GlobalOutline } from 'antd-mobile-icons';
import { useDispatch } from 'react-redux';

import * as ToolUser from '../tools/ToolUser';

import { updateUser } from '../reducers/userSlice';

export default function HeaderRight(props) {
  const dispatch = useDispatch();

  return (
    <Button
      color="primary"
      fill="none"
      size="large"
      shape="rounded"
      onClick={() => {
        Modal.show({
          content: I18n.t('headerRightLocaleModalTitle'),
          closeOnAction: true,
          closeOnMaskClick: true,
          actions: [
            {
              key: 'zh-tw',
              text: '繁體中文',
              primary: true,
            },
            {
              key: 'en',
              text: 'English',
              primary: true,
            },
            {
              key: null,
              text: I18n.t('headerRightLocaleButtonClose'),
            },
          ],
          onAction: (action) => {
            const locale = action.key;
            if (locale) {
              dispatch(setLocale(locale));
              dispatch(updateUser({
                locale,
              }));
              ToolUser.updateLocale(locale);
            }
          },
        });
      }}
    >
      <GlobalOutline />
    </Button>
  );
};
