import { createSlice } from '@reduxjs/toolkit';

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState: {
    value: {},
  },
  reducers: {
    updateMetadata: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
})

export const { updateMetadata } = metadataSlice.actions;

export default metadataSlice.reducer;

