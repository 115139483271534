import { createSlice } from '@reduxjs/toolkit';

export const evChargerSlice = createSlice({
  name: 'evCharger',
  initialState: {
    value: {},
  },
  reducers: {
    updateEvCharger: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
})

export const { updateEvCharger } = evChargerSlice.actions;

export default evChargerSlice.reducer;

