import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavBar, Card, Space, Button, Input, Image, Tag, Toast, Picker } from 'antd-mobile';
import { GlobalOutline, DownOutline } from 'antd-mobile-icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolLocale from '../../tools/ToolLocale';

import logo from '../../images/logo-horizontal-white.png';
import HeaderRight from '../../components/HeaderRight';

import { updateEvCharger } from '../../reducers/evChargerSlice';

export default function ChargerScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const evChargerData = useSelector(state => state.evCharger.value);
  const organizationData = useSelector(state => state.organization.value);
  const userData = useSelector(state => state.user.value);

  const [durationPickerVisible, setDurationPickerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState();
  const [durations, setDurations] = useState([]);

  useEffect(() => {
    if (!userData || !userData.id) {
      navigate(`/${evChargerData.evChargerSlot.id}`);
    }

    let durationMax = 10800;

    if (userData.organizationUsageGroup) {
      if (userData.organizationUsageGroup.rules && userData.organizationUsageGroup.rules.duration) {
        durationMax = userData.organizationUsageGroup.rules.duration * 60;
      }
    }

    const durationsNew = [];
    const durationMaxIndex = Math.ceil(durationMax / 1800);
    for (let i = 1; i <= durationMaxIndex; ++i) {
      durationsNew.push(i === durationMaxIndex ? durationMax : i * 1800);
    }
    setDurations(durationsNew);
    setSelectedDuration([durationsNew[0]]);

  }, []);

  const submit = async () => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('POST', `/v1/ev-charger-user-transaction/visitor`, {
        organizationVisitorId: userData.id,
        chargerSlotId: evChargerData.evChargerSlot.id,
        selectedDuration: selectedDuration[0],
      });

      const evChargerSlot = await ToolRequest.request('GET', `/v1/ev-charger-slot/${evChargerData.evChargerSlot.id}`);
      dispatch(updateEvCharger({
        evChargerSlot,
      }));

      navigate(`/${evChargerData.evChargerSlot.id}`);

      Toast.show({
        icon: 'success',
        content: I18n.t('chargerScreenStartChargingSuccessMessage'),
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        switch (err.response.data) {
          case 'CHARGER_SLOT_NOT_FOUND': {
            Toast.show({
              icon: 'fail',
              content: I18n.t('chargerScreenErrorInvalidChargerSlot'),
            });
            break;
          }
          case 'VISITOR_NOT_FOUND': {
            Toast.show({
              icon: 'fail',
              content: I18n.t('chargerScreenErrorVisitorNotFound'),
              duration: 5000,
            });
            break;
          }
          case 'USER_OUT_OF_USAGE': {
            Toast.show({
              icon: 'fail',
              content: I18n.t('chargerScreenErrorUserOutOfUsage', {
                organizationName: organizationData.displayName,
              }),
              duration: 5000,
            });
            break;
          }
          default: {
            Toast.show({
              icon: 'fail',
              content: I18n.t('errorUnknown'),
            });
            break;
          }
        }
      }

      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <NavBar
        back={null}
        right={<HeaderRight />}
      />

      <div className="header">
        <Image src={logo} fit="scale-down" />
      </div>

      <Card
        title={<>
          <div className="organization-name">{organizationData.displayName}</div>
          <div className="charger-name">
            <Tag color="#DFEBF0">{evChargerData.name}</Tag>
          </div>
        </>}
      >
        {userData.status === 'AVAILABLE' ? (
          <Space block direction="vertical" style={{ '--gap': '24px' }}>
            <div>{I18n.t('chargerScreenGreeting', {
              name: userData.displayName,
            })}</div>

            <div>{I18n.t('chargerScreenDescription')}</div>

            <Button
              block
              color="primary"
              fill="outline"
              shape="rounded"
              size="large"
              onClick={() => setDurationPickerVisible(true)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1, paddingLeft: 18 }}>{ToolLocale.formatDurationLabel(selectedDuration)}</div>
                <DownOutline />
              </div>
            </Button>

            <div>{I18n.t('chargerScreenEstimatedCompletionTime', {
              time: moment().add(selectedDuration, 'second').format('D/M HH:mm'),
            })}</div>

            <Button
              block
              color="primary"
              shape="rounded"
              size="large"
              loading={loading}
              onClick={() => submit()}
            >
              {I18n.t('chargerScreenButtonSubmit')}
            </Button>
          </Space>
        ) : (
          <Space block direction="vertical" style={{ '--gap': '24px' }}>
            <div>{I18n.t('chargerScreenGreeting', {
              name: userData.displayName,
            })}</div>

            <div>{userData.status === 'EXPIRED'
              ? I18n.t('chargerScreenDescriptionExpired', {
                organizationName: organizationData.displayName,
              })
              : I18n.t('chargerScreenDescriptionOutOfUsage', {
                organizationName: organizationData.displayName,
              })}</div>

            <Button
              block
              color="primary"
              fill="outline"
              shape="rounded"
              size="large"
              onClick={() => navigate(`/${evChargerData.evChargerSlot.id}`)}
            >
              {I18n.t('chargerScreenButtonBack')}
            </Button>
          </Space>
        )}
      </Card>

      <Picker
        columns={[durations.map(duration => ({
          label: ToolLocale.formatDurationLabel(duration),
          value: duration,
        }))]}
        visible={durationPickerVisible}
        onClose={() => setDurationPickerVisible(false)}
        onConfirm={(value) => setSelectedDuration(value)}
        value={selectedDuration}
      />

    </div>
  );
};
