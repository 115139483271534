import Moment from 'react-moment';
import 'moment/locale/zh-hk';

export const updateLocale = (locale) => {
	let localeNew = 'zh-hk';

	switch (locale) {
		case 'zh-tw':
			localeNew = 'zh-hk';
			break;
		default:
			localeNew = 'en';
			break;
	}

	Moment.globalLocale = localeNew;
};