import * as React from 'react';

export default function GraiseLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        d="M911 492H753.84A143 143 0 0 0 511 362.82a143 143 0 0 0-240.66 138C295.86 644.09 510 767 510 767s93.12-53.94 165.58-132H613a103 103 0 0 1-103-103h380.48C880 731.73 714.27 891 512 891c-209 0-379-170-379-379 0-225.08 196-377.68 379.14-379h2.33c142.08 0 283.66 93.15 344.92 227.22a20 20 0 1 0 36.38-16.62c-31.62-69.22-85.08-132.08-150.52-177C675.09 118.41 595.4 93 514.62 93h-2.76c-104.36.74-209.59 44.34-288.71 119.6C139.22 292.44 93 398.77 93 512a419.1 419.1 0 0 0 805.06 163.1A416.25 416.25 0 0 0 931 512a20 20 0 0 0-20-20Z"
        style={{
          fill: props.color,
        }}
        data-name="Layer 2"
      />
    </svg>
  );
};
