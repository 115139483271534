import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavBar, Card, Space, Button, Image, Tag, Toast } from 'antd-mobile';
import { GlobalOutline } from 'antd-mobile-icons';

import * as ToolRequest from '../../tools/ToolRequest';

import logo from '../../images/logo-horizontal-white.png';
import HeaderRight from '../../components/HeaderRight';
import GraiseLogo from '../../components/Logo';

import { updateEvCharger } from '../../reducers/evChargerSlice';

export default function HomeScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const evChargerData = useSelector(state => state.evCharger.value);
  const organizationData = useSelector(state => state.organization.value);

  const currentActiveTransaction = evChargerData.evChargerSlot.currentActiveTransaction || null;

  const [durationLeftText, setDurationLeftText] = useState('-');
  const [durationHeight, setDurationHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reloadEvChargerTick, setReloadEvChargerTick] = useState(0);

  const refreshRender = () => {
    if (currentActiveTransaction) {
      const durationLeft = Math.max(0, Math.floor(moment.duration(moment(currentActiveTransaction.startAt).add(currentActiveTransaction.selectedDuration, 'second').diff()).asSeconds()));
      setDurationLeftText(`${Math.floor(durationLeft / 3600)}:${('0'+(Math.floor(durationLeft / 60) % 60).toString()).substr(-2)}:${('0' + (durationLeft % 60)).substr(-2)}`);
      setDurationHeight(Math.min(Math.max(0, moment().diff(currentActiveTransaction.startAt, 'second') / currentActiveTransaction.selectedDuration), 1) * 100);
    }
  };

  const reloadEvCharger = async () => {
    setLoading(true);

    try {
      const evChargerSlot = await ToolRequest.request('GET', `/v1/ev-charger-slot/${evChargerData.evChargerSlot.id}`);
      dispatch(updateEvCharger({
        evChargerSlot,
      }));

      // complete charging
      if (currentActiveTransaction && evChargerSlot.status !== 'IN_USE') {
        Toast.show({
          icon: 'success',
          content: I18n.t('homeScreenCompleteChargingSuccessMessage'),
        });
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let chargingInterval;

    chargingInterval = setInterval(() => {
      if (!loading) {
        setReloadEvChargerTick(reloadEvChargerTick + 1);

        if (evChargerData.evChargerSlot.status === 'IN_USE') {
          if (reloadEvChargerTick < 5) {    // 5 sec
            return;
          }
        } else {
          if (reloadEvChargerTick < 60) {   // 1 min
            return;
          }
        }

        reloadEvCharger();

        setReloadEvChargerTick(0);
      }

      refreshRender();
    }, 1000);

    if (evChargerData.evChargerSlot.status === 'IN_USE') {
      refreshRender();
    }

    return () => {
      clearInterval(chargingInterval);
    };
  }, [loading, evChargerData, reloadEvChargerTick]);

  return (
    <div className="home-container">
      <NavBar
        back={null}
        right={<HeaderRight />}
      />

      <div className="header">
        <Image src={logo} fit="scale-down" />
      </div>

      <Card
        title={<>
          <div className="organization-name">{organizationData.displayName}</div>
          <div className="charger-name">
            <Tag color="#DFEBF0">{evChargerData.name}</Tag>
          </div>
        </>}
      >
        {evChargerData.evChargerSlot.status === 'AVAILABLE' ? (
          <Space block direction="vertical" style={{ '--gap': '24px' }}>
            <div>{I18n.t('homeScreenAvailableDescription1', {
              organizationName: organizationData.displayName,
            })}</div>
            <div>{I18n.t('homeScreenAvailableDescription2')}</div>
            <div>{I18n.t('homeScreenAvailableDescription3')}</div>

            <Space block direction="vertical" style={{ '--gap': '12px' }}>
              <Button
                block
                color="primary"
                size="large"
                shape="rounded"
                onClick={() => {
                  window.location.href = `graise://ev/${evChargerData.evChargerSlot.id}`;
                }}
              >
                {I18n.t('homeScreenButtonOpenApp')}
              </Button>
              <Button
                block
                color="primary"
                fill="outline"
                size="large"
                shape="rounded"
                onClick={() => navigate('login')}
              >
                {I18n.t('homeScreenButtonLoginVisitor')}
              </Button>
            </Space>

            <Space className="agreement-container" wrap>
              <span>{I18n.t('homeScreenAgreement1')}</span>
              <a href="https://doc.graise.io/terms-of-use" target="_blank">{I18n.t('homeScreenAgreement2')}</a>
              <span>{I18n.t('homeScreenAgreement3')}</span>
              <a href="https://doc.graise.io/privacy-policy" target="_blank">{I18n.t('homeScreenAgreement4')}</a>
            </Space>
          </Space>
        ) : (
          evChargerData.evChargerSlot.status === 'IN_USE' ? (
            <Space block direction="vertical" style={{ '--gap': '24px' }}>
              <div>{I18n.t('homeScreenInUseDescription1')}</div>

              <div className="charging-progress-container">
                <GraiseLogo
                  width={200}
                  height={200}
                  color="#F2F4F5"
                />

                <div className="charging-progress-overlay" style={{ height: `${durationHeight}%` }}>
                  <GraiseLogo
                    width={200}
                    height={200}
                    color="#FFB323"
                  />
                </div>

                <div className="charging-progress-overlay-content">
                  <div className="charging-progress-overlay-content-title">{I18n.t('homeScreenInUseOverlayTitle')}</div>
                  <div className="charging-progress-overlay-content-duration">{durationLeftText}</div>
                </div>
              </div>

              <div className="charging-bottom">
                <div className="charging-bottom-esimated-time">
                  {moment(currentActiveTransaction.startAt).add(currentActiveTransaction.selectedDuration, 'second').format('YYYY-MM-DD HH:mm')}
                </div>
                <div className="charging-bottom-title">{I18n.t('homeScreenInUseBottomTitle')}</div>
              </div>
            </Space>
          ) : (
            <Space block direction="vertical" style={{ '--gap': '24px' }}>
              <div>{I18n.t('homeScreenDisabledDescription')}</div>
            </Space>
          )
        )}
      </Card>

      <div className="footer">
        <Button
          size="mini"
          fill="none"
          color="white"
          onClick={() => {
            window.open('http://graisegreen.app.link/TrZYlzoEnxb');
          }}
        >
          {I18n.t('homeScreenFooterDownloadApp')}
        </Button>
      </div>
    </div>
  );
};
