export default {
  "chargerScreenButtonBack": "返回",
  "chargerScreenButtonSubmit": "開始充電",
  "chargerScreenDescription": "選擇充電時間，立即開始充電：",
  "chargerScreenDescriptionExpired": "對不起。您的訪客訪問權限已過期。您可以聯繫 %{organizationName} 以獲得進一步的幫助。",
  "chargerScreenDescriptionOutOfUsage": "對不起。您的訪客訪問權限已達到使用限制。您可以聯繫 %{organizationName} 以獲得進一步的幫助。",
  "chargerScreenErrorInvalidChargerSlot": "請稍後重試或使用其他 EV 充電器",
  "chargerScreenErrorUserOutOfUsage": "對不起。您的訪客訪問權限已達到使用限制。您可以聯繫 %{organizationName} 以獲得進一步的幫助。",
  "chargerScreenErrorVisitorNotFound": "無法找到您的訪客訪問權限。請再次檢查您的電子郵件",
  "chargerScreenEstimatedCompletionTime": "預計在 %{time} 完成",
  "chargerScreenGreeting": "你好 %{name}！",
  "chargerScreenStartChargingSuccessMessage": "成功啟動充電器, 請在已選時間後回來完成充電",

  "errorScreenButtonReload": "重新加載",
  "errorScreenDescription": "請稍後重試或使用其他 EV 充電器。",
  "errorScreenTitle": "抱歉﹐發生錯誤",

  "errorUnknown": "未知問題。請再試一次",

  "headerRightLocaleButtonClose": "關閉",
  "headerRightLocaleModalTitle": "選擇語言",

  "homeScreenAgreement1": "繼續即表示您同意我們的",
  "homeScreenAgreement2": "使用條款",
  "homeScreenAgreement3": "和",
  "homeScreenAgreement4": "隱私政策",
  "homeScreenAvailableDescription1": "請確保您可以訪問 %{organizationName}，方可以為您的 EV 充電。",
  "homeScreenAvailableDescription2": "如果您有 Graise 帳戶，只需打開 Graise app，掃描充電器上的二維碼。",
  "homeScreenAvailableDescription3": "如果您是訪客，請點擊下面按鈕。輸入您從組織發送邀請的電子郵件地址，或者電子郵件中的 16 位使用碼。",
  "homeScreenButtonLoginVisitor": "我是訪客",
  "homeScreenButtonOpenApp": "打開 Graise app",
  "homeScreenCompleteChargingSuccessMessage": "充電完成",
  "homeScreenDisabledDescription": "對不起。此 EV 充電器不可用。請找另一個充電器或稍後再回來。",
  "homeScreenFooterDownloadApp": "下載 Graise app（適用於 iOS 和 Android）",
  "homeScreenInUseBottomTitle": "預計充電結束時間",
  "homeScreenInUseDescription1": "此 EV 充電器正在使用中。如果這不是您的 EV，請另找一台充電器。",
  "homeScreenInUseOverlayTitle": "剩餘時間",

  "loginScreenButtonCode": "如果您沒有關聯的電子郵件地址，請點擊此處並輸入您的訪客代碼",
  "loginScreenButtonEmail": "點擊此處輸入您關聯的電子郵件地址",
  "loginScreenButtonSubmit": "繼續",
  "loginScreenErrorInvalidChargerSlot": "請稍後重試或使用其他 EV 充電器",
  "loginScreenErrorInvalidCodeLength": "請重新輸入 16 位使用碼",
  "loginScreenErrorInvalidEmail": "電郵地址不正確",
  "loginScreenErrorUserExists": "無法找到您的訪問權限，但您似乎有一個與此電子郵件相關聯的帳戶。請打開 Graise app，登錄您的帳戶並重試",
  "loginScreenErrorVisitorNotFound": "無法找到您的訪客訪問權限。請再次檢查您的電子郵件",
  "loginScreenLabelCode": "輸入您的 16 位使用碼（顯示在您的邀請電子郵件中）",
  "loginScreenLabelEmail": "輸入您的組織已發送至的電子郵件地址：",
  "loginScreenSuccessMessage": "驗證成功"
}