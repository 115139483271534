import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { NavBar, ErrorBlock, Button } from 'antd-mobile';

import HeaderRight from '../../components/HeaderRight';

import logo from '../../images/logo-white.png';

export default function ErrorScreen(props) {
  return (
    <>
      <NavBar
        back={null}
        right={<HeaderRight />}
        style={{ position: 'absolute', left: 0, right: 0 }}
      />
      <ErrorBlock
        fullPage
        image={logo}
        title={I18n.t('errorScreenTitle')}
        description={I18n.t('errorScreenDescription')}
      >
        <Button
          color="primary"
          size="large"
          onClick={() => window.location.reload()}
          style={{ marginTop: 16 }}
        >
          {I18n.t('errorScreenButtonReload')}
        </Button>
      </ErrorBlock>
    </>
  );
};
