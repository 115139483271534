import React from 'react';

import { SpinLoading } from 'antd-mobile';

export default function SpinFull(props) {
  return (
    <div className="spin-full-container">
      <SpinLoading color="white" style={{ '--size': '36px' }} />
    </div>
  );
};
