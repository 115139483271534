import { createSlice } from '@reduxjs/toolkit';

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    value: {},
  },
  reducers: {
    updateOrganization: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
})

export const { updateOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;

