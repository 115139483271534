import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { store } from './reducers';
import { MemoryRouter, Routes, Route } from 'react-router-dom';

import SplashScreen from './screens/Splash/SplashScreen';
import ErrorScreen from './screens/Error/ErrorScreen';

import './less/index.less';
import './less/theme.less';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MemoryRouter initialEntries={[window.location.pathname]}>>
        <Routes>
          <Route path="/:chargerSlotId*" element={<SplashScreen />} />
          <Route index element={<ErrorScreen />} />
        </Routes>
      </MemoryRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
