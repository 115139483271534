import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { i18nReducer, loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import translations from '../locales';

import metadataReducer from './metadataSlice';
import userReducer from './userSlice';
import evChargerReducer from './evChargerSlice';
import organizationReducer from './organizationSlice';

export const store = configureStore({
  reducer: {
    i18n: i18nReducer,
    metadata: metadataReducer,
    user: userReducer,
    evCharger: evChargerReducer,
    organization: organizationReducer,
  },
  middleware: getDefaultMiddleware(),
});

store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('zh-tw'));
syncTranslationWithStore(store);
