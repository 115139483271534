export default {
  "chargerScreenButtonBack": "Back",
  "chargerScreenButtonSubmit": "Start charging",
  "chargerScreenDescription": "Select the charging duration and start charging now:",
  "chargerScreenDescriptionExpired": "Sorry. Your visitor access has expired. You may contact %{organizationName} for further assistance.",
  "chargerScreenDescriptionOutOfUsage": "Sorry. Your visitor access has reached usage limit. You may contact %{organizationName} for further assistance.",
  "chargerScreenErrorInvalidChargerSlot": "Please try again later or use another EV charger",
  "chargerScreenErrorUserOutOfUsage": "Sorry. Your visitor access has reached usage limit. You may contact %{organizationName} for further assistance.",
  "chargerScreenErrorVisitorNotFound": "Unable to find your visitor access. Please check again your email",
  "chargerScreenEstimatedCompletionTime": "Estimated completion at %{time}",
  "chargerScreenGreeting": "Greetings %{name}!",
  "chargerScreenStartChargingSuccessMessage": "Successfully start the charger. Please come back to complete the charging after selected duration",

  "errorScreenButtonReload": "Reload",
  "errorScreenDescription": "Please try again later or use another EV charger.",
  "errorScreenTitle": "Sorry. Something went wrong",

  "errorUnknown": "未知錯誤, 請重試",

  "headerRightLocaleButtonClose": "Close",
  "headerRightLocaleModalTitle": "Select Language",

  "homeScreenAgreement1": "By continuing, you agree to our ",
  "homeScreenAgreement2": "Terms of Use",
  "homeScreenAgreement3": "and",
  "homeScreenAgreement4": "Privacy Policy",
  "homeScreenAvailableDescription1": "To charge your EV, ensure you have access from %{organizationName}.",
  "homeScreenAvailableDescription2": "If you have a Graise account, simply open the Graise app, scan the QR code on the charger.",
  "homeScreenAvailableDescription3": "If you're a visitor, click the button below. Enter the email address where you received the invitation from your organization, or copy the 16-letter code from the email.",
  "homeScreenButtonLoginVisitor": "I'm a Visitor",
  "homeScreenButtonOpenApp": "Open Graise app",
  "homeScreenCompleteChargingSuccessMessage": "Charging is complete",
  "homeScreenDisabledDescription": "Sorry. This EV charger is unavailable. Please find another charger or come back later.",
  "homeScreenFooterDownloadApp": "Download Graise app (available for iOS & Android)",
  "homeScreenInUseBottomTitle": "Estimated completion time",
  "homeScreenInUseDescription1": "This EV charger is being in use. Please find another charger if this is not your EV.",
  "homeScreenInUseOverlayTitle": "Remaining Duration",

  "loginScreenButtonCode": "Click here to enter your visitor code, if you do not have an email address associated",
  "loginScreenButtonEmail": "Click here to enter your email address associated",
  "loginScreenButtonSubmit": "Continue",
  "loginScreenErrorInvalidChargerSlot": "Please try again later or use another EV charger",
  "loginScreenErrorInvalidCodeLength": "Please check again the 16-letter code",
  "loginScreenErrorInvalidEmail": "Invalid Email address",
  "loginScreenErrorUserExists": "Unable to find your access, but it seems you have an account associated with this email. Please open Graise app, login your account and try again",
  "loginScreenErrorVisitorNotFound": "Unable to find your visitor access. Please check again your email",
  "loginScreenLabelCode": "Enter your 16-letter code (shown in your invitation email)",
  "loginScreenLabelEmail": "Enter your email address where you organization has sent to:",
  "loginScreenSuccessMessage": "Verify successfully"
}